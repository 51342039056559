var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[(!_vm.isUploaded)?_c('div',{staticClass:"col-lg-12 px-0"},[_c('div',{staticClass:"card p-half"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-title"},[_vm._v(" Upload Data ")]),_c('div',{staticClass:"alert alert-danger p-1",attrs:{"role":"alert"}},[_c('h4',{staticClass:"text-danger"},[_c('p-icon',{staticClass:"pr-1",attrs:{"name":"bi-exclamation-circle-fill"}}),_vm._v(" Please always use the latest template. Last update time 2024/08/10 "),_c('div',{staticClass:"badge badge-warning badge-glow mx-2"},[_vm._v(" ATTENTION ")])],1)]),_c('div',{staticClass:"row mb-1 justify-content-between mr-half"},[_c('div',{staticClass:"ml-1 border border-primary rounded"},[_c('span',{staticClass:"px-2"},[_vm._v("Choose your source file ...")]),_c('input',{ref:"fileInput",attrs:{"type":"file","accept":".xlsx, .xls, .csv","hidden":""},on:{"change":_vm.onChange}}),_c('dx-util-button',{staticClass:"py-half px-2 ",attrs:{"text":"Browse","type":"default"},nativeOn:{"click":function($event){return _vm.$refs.fileInput.click()}}})],1),_c('dx-util-button',{staticClass:"py-half px-2 ",attrs:{"text":"Download Excel Template","icon":"bi bi-filetype-xlsx","type":"default"},nativeOn:{"click":function($event){return _vm.downloadExcelTemplate.apply(null, arguments)}}})],1)]),_c('div',{staticClass:"row px-1 mx-half"},[_c('dx-data-grid',{ref:"instructionsGridRef",staticClass:"mt-1",attrs:{"id":"instructionsGrid","data-source":_vm.instructions,"allow-column-reordering":true,"allow-column-resizing":true,"column-resizing-mode":"widget","column-auto-width":true,"show-column-lines":true,"show-row-lines":true,"show-borders":true,"row-alternation-enabled":false,"hover-state-enabled":true},scopedSlots:_vm._u([{key:"titleCellTemplate",fn:function(ref){
var data = ref.data;
return [_c('span',{staticClass:"dx-product-title"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"fieldNameTemplate",fn:function(ref){
var data = ref.data;
return [_c('span',{staticClass:"font-weight-bold text-primary"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"requiredTemplate",fn:function(ref){
var data = ref.data;
return [_c('span',{class:("text-" + (data.value === 'Required' ? 'danger' : 'warning'))},[_vm._v(" "+_vm._s(data.value)+" ")])]}}],null,false,2120870490)},[_c('dx-paging',{attrs:{"enabled":false}}),_c('dx-column',{attrs:{"data-field":"field_name","cell-template":"fieldNameTemplate","width":"200"}}),_c('dx-column',{attrs:{"data-field":"required","cell-template":"requiredTemplate","sort-index":0,"sort-order":"desc","width":"120"}}),_c('dx-column',{attrs:{"data-field":"instructions","cell-template":"titleCellTemplate"}}),_c('dx-column',{attrs:{"data-field":"notes","cell-template":"titleCellTemplate"}})],1)],1)])]):_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text d-flex justify-content-between"},[_c('h4',[_vm._v("Uploaded Data")]),_c('p-icon',{attrs:{"role":"button","name":"bi-x-lg"},nativeOn:{"click":function($event){return _vm.closeCard.apply(null, arguments)}}})],1),_c('div',[_c('xlsx-read',{attrs:{"file":_vm.file}},[_c('xlsx-json',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [(collection)?_c('fileupolad-list',{attrs:{"csv-data":collection}}):_vm._e()]}}])})],1)],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }