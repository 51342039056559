<template>
  <div class="container-fluid">
    <div class="row">
      <div v-if="!isUploaded" class="col-lg-12 px-0">
        <div class="card p-half">
          <div class="card-body">
            <div class="card-title">
              Upload Data
            </div>
            <div class="alert alert-danger p-1" role="alert">
              <h4 class="text-danger">
                <p-icon name="bi-exclamation-circle-fill" class="pr-1" />
                Please always use the latest template. Last update time
                2024/08/10
                <div class="badge badge-warning badge-glow mx-2">
                  ATTENTION
                </div>
              </h4>
            </div>
            <div class="row mb-1 justify-content-between mr-half">
              <div class="ml-1 border border-primary rounded">
                <span class="px-2">Choose your source file ...</span>
                <input ref="fileInput" type="file" accept=".xlsx, .xls, .csv" hidden @change="onChange">
                <dx-util-button
                  class="py-half px-2 "
                  text="Browse"
                  type="default"
                  @click.native="$refs.fileInput.click()"
                />
              </div>
              <dx-util-button
                class="py-half px-2 "
                text="Download Excel Template"
                icon="bi bi-filetype-xlsx"
                type="default"
                @click.native="downloadExcelTemplate"
              />
            </div>
          </div>
          <div class="row px-1 mx-half">
            <dx-data-grid
              id="instructionsGrid"
              ref="instructionsGridRef"
              class="mt-1"
              :data-source="instructions"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              column-resizing-mode="widget"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :show-borders="true"
              :row-alternation-enabled="false"
              :hover-state-enabled="true"
            >
              <dx-paging :enabled="false" />
              <dx-column
                data-field="field_name"
                cell-template="fieldNameTemplate"
                width="200"
              />
              <dx-column
                data-field="required"
                cell-template="requiredTemplate"
                :sort-index="0"
                sort-order="desc"
                width="120"
              />
              <dx-column
                data-field="instructions"
                cell-template="titleCellTemplate"
              />
              <dx-column data-field="notes" cell-template="titleCellTemplate" />
              <template #titleCellTemplate="{data}">
                <span class="dx-product-title">
                  {{ data.value }}
                </span>
              </template>
              <template #fieldNameTemplate="{data}">
                <span class="font-weight-bold text-primary">
                  {{ data.value }}
                </span>
              </template>
              <template #requiredTemplate="{data}">
                <span
                  :class="
                    `text-${data.value === 'Required' ? 'danger' : 'warning'}`
                  "
                >
                  {{ data.value }}
                </span>
              </template>
            </dx-data-grid>
          </div>
        </div>
      </div>
      <div v-else class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-text d-flex justify-content-between">
              <h4>Uploaded Data</h4>
              <p-icon role="button" name="bi-x-lg" @click.native="closeCard" />
            </div>
            <div>
              <xlsx-read :file="file">
                <xlsx-json>
                  <template #default="{collection}">
                    <fileupolad-list v-if="collection" :csv-data="collection" />
                  </template>
                </xlsx-json>
              </xlsx-read>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XlsxRead, XlsxJson } from 'vue-xlsx'
import userProfileService from '@/http/requests/system/userProfileService'
import { Notify } from '@robustshell/utils/index'
import FileUploadList from './file-upload-list/FileUploadList.vue'

export default {
  components: {
    XlsxRead,
    XlsxJson,
    'fileupolad-list': FileUploadList,
  },
  data() {
    return {
      file: null,
      isUploaded: false,
      saveForm: false,
      fields: ['field_name', 'required', 'instructions', 'notes'],
      instructions: [
        {
          id: 1,
          field_name: 'TrackingNumber',
          required: 'Optional',
          instructions: "It's essential that you enter tracking numbers to enable us to track your orders properly.",
          notes: 'To upload an item into our system, you must at least enter TrackingNumber or OrderNumber for each item. If both of these fields are blank, then the item wil not be uploaded',
        },
        {
          id: 2,
          field_name: 'OrderNumber',
          required: 'Optional',
          instructions: "It's essential that you enter order numbers. It enables us to validate the items in each package and address any issues if an item has any problem",
          notes: 'To upload an item into our system, you must at least enter TrackingNumber or OrderNumber for each item. If both of these fields are blank, then the item wil not be uploaded',
        },
        {
          id: 3,
          field_name: 'OrderNumberSold',
          required: 'Optional',
          instructions: "It's essential that you enter order numbers for Sold items. It enables us to validate the items sold on your e-commerce store",
        },
        {
          id: 4,
          field_name: 'PalletId',
          required: 'Optional',
          instructions: 'Plase provide pallet id/tracking in this column if there is any',
        },
        {
          id: 5,
          field_name: 'PurchaseDate',
          required: 'optional',
          instructions: 'Please enter your purchase date in YYYY-MM-DD or MM/DD/YY format (ex. 2021-01-25 or 01/25/21)',
          notes: 'Make sure to enter correct the date in correct format',
        },
        {
          id: 6,
          field_name: 'ShipmentDate',
          required: 'Required',
          instructions: 'Please enter your shipment date in YYYY-MM-DD or MM/DD/YY format (ex. 2021-01-25 or 01/25/21). This will enable us to know expected items arrange our operations accordingly',
          notes: 'Make sure to enter correct the date in correct format',
        },
        {
          id: 7,
          field_name: 'ExpirationDate',
          required: 'Optional',
          instructions: 'It is important to provide expiration date for eligible items in YYYY-MM-DD or MM/DD/YY format (ex. 2021-01-25 or 01/25/21)',
        },
        {
          id: 8,
          field_name: 'ASIN',
          required: 'Optional',
          instructions: "It's very important to provide ASIN. We can identify the item and replenish inventory in Amazon",
          notes: 'For bundle and Multipack items, please enter bundle or Multipack ASIN',
        },
        {
          id: 9,
          field_name: 'MSKU',
          required: 'Optional',
          instructions: 'Please enter MSKU. MSKU is required if ASIN is not provided for ecommerce platforms other than Amazon',
          notes: 'Must enter ASIN or MSKU. If both missing in an entry (line), the file will not be loaded.',
        },
        {
          id: 10,
          field_name: 'UPC',
          required: 'Optional',
          instructions: "It's critical to provide UPC code. When we scan incoming items, they will match to your upload file. A record without UPC will be void",
          notes: '',
        },
        {
          id: 11,
          field_name: 'FNSKU',
          required: 'Optional',
          instructions: 'Please provide FNSKU. Amazon sold items with this code',
          notes: '',
        },
        {
          id: 12,
          field_name: 'SerialNo',
          required: 'Optional',
          instructions: 'Please provide Serial Number for applicable products',
          notes: '',
        },
        {
          id: 13,
          field_name: 'ProductTitle',
          required: 'Required',
          instructions: 'Please enter ProductTitle. If the items send have missing information, we can identify the item manually with the title',
          notes: '',
        },
        {
          id: 14,
          field_name: 'ManufactureOrSupplier',
          required: 'Required',
          instructions: 'Please provide ManufacturerOrSupplier. ',
          notes: '',
        },
        {
          id: 15,
          field_name: 'ProductGroup',
          required: 'Optional',
          instructions: 'Please enter ProductGroup. We can classify and prepare the items accordingly',
          notes: '',
        },
        {
          id: 16,
          field_name: 'NumberOfUnits',
          required: 'Required',
          instructions: "Please provide NumberofUnits. It must be number of sellable items. It should include number of sellable items for Multipack and Bundle. Don't enter the number of individual items for Multipack and Bundle. For example if you have 5 multipack and each multipack includes 4 items, you should enter 5. The content of bundle and multipack will be shown in Title and/or notes",
          notes: '',
        },
        {
          id: 17,
          field_name: 'Condition',
          required: 'Required',
          instructions: "Please enter the condition of the items. It's a requirement by Amazon and most of the ecommerce sites",
          notes: '',
        },
        {
          id: 18,
          field_name: 'Cost',
          required: 'Required',
          instructions: 'Please enter the cost of the item. ',
          notes: '',
        },
        {
          id: 19,
          field_name: 'SalePrice',
          required: 'Required',
          instructions: 'Please enter the SalePrice. ',
          notes: '',
        },
        {
          id: 20,
          field_name: 'Fragile',
          required: 'Required',
          instructions: "Please select Yes or No for each item. It's an Amazon requirement for the shipped items",
          notes: '',
        },
        {
          id: 21,
          field_name: 'MultiPack',
          required: 'Required',
          instructions: 'Please select Yes or No. So, we can identify if we will prepare the items as multipack before sending to customers or Amazon',
          notes: '',
        },
        {
          id: 22,
          field_name: 'Bundle',
          required: 'Required',
          instructions: 'Please select Yes or No. if you enter Required, enter the ASIN for the bundle items. ',
          notes: '',
        },
        {
          id: 23,
          field_name: 'CountperBundle',
          required: 'Required',
          instructions: 'Please enter the number of items that will be used in a bundle or multipack. For example, if you want to use 2 of item A and 3 of item B in a bundle, please enter 5 here. ',
          notes: 'Please enter bundle or multipack ASIN for bundle and multipack products',
        },
        {
          id: 24,
          field_name: 'Action',
          required: 'Required',
          instructions: 'Please identify the Action for items. Selection at the first row will be copied over to the next rows as far as if you have a TrackingNumber or OrderNumber. If there are exceptions in the upload file, please go to the specific row and select an action from available options',
          notes: '',
        },
        {
          id: 25,
          field_name: 'Notes',
          required: 'Optional',
          instructions: 'Enter important product notes such as instructions and content etc. here',
          notes: '',
        },
        {
          id: 25,
          field_name: 'locationName',
          required: 'Optional',
          instructions: 'Enter warehouse location name.',
          notes: 'This is not required for Prep Center clients!',
        },
        {
          id: 26,
          field_name: 'Tags',
          required: 'Optional',
          instructions: 'Enter product tags',
          notes: '',
        },
        {
          id: 27,
          field_name: 'PurchasedBy',
          required: 'Optional',
          instructions: 'Enter buyer name',
          notes: '',
        },
        {
          id: 27,
          field_name: 'Source',
          required: 'Optional',
          instructions: 'Enter source information. Source defines where the item has been sourced.',
          notes: '',
        },
      ],
    }
  },
  methods: {
    onChange(event) {
      const file = event.target.files ? event.target.files[0] : null
      const name = file.name
      const lastDot = name.lastIndexOf('.')
      const ext = name.substring(lastDot + 1)
      if (ext !== 'xlsx' && ext !== 'xls' && ext !== 'csv') {
        Notify.warning(
          'You could uploaded only xlsx xls, csv extensions files. Please always use PSH Excel Tepmlate.',
        )
        this.downloadExcelTemplate()
        return
      }

      this.file = file
      if (this.file != null) {
        this.isUploaded = !this.isUploaded
      }
    },
    closeCard() {
      this.isUploaded = !this.isUploaded
      this.file = null
      this.selected = []
    },
    downloadExcelTemplate() {
      userProfileService.downloadExcelTemplate().then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'PSH_FileUploadTemplate.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
  },
}
</script>
