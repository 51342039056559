<template>
  <div>
    <div class="row">
      <div class="col-12 p-1">
        <div class="d-flex justify-content-start align-items-center">
          <dx-util-text-box
            v-model="batchName"
            label="Batch Name"
            class="mr-1 col"
          />
          <dx-util-text-box
            v-if="hasPermission"
            v-model="accountNo"
            :visible="isCompanyTypeWarehouse"
            label="Account or Suite No"
            class="mr-1 px-0 col"
            @focus-out="onFocusOut"
            @key-down="getCompanyByAccount"
          >
            <dx-text-box-button
              location="after"
              name="accountTextBoxButton"
              :options="accountTextBoxButtonOptions"
            />
          </dx-util-text-box>
          <dx-util-select-box
            v-model="selectedStore"
            label="Store"
            :data-source="stores"
            display-expr="text"
            value-expr="value"
            class="mr-1 col px-0"
          />
          <dx-util-select-box
            v-model="selectedWarehouse"
            label="Warehouse"
            :data-source="warehouses"
            display-expr="text"
            value-expr="value"
            class="mr-1 col  px-0"
          />
          <dx-util-check-box
            v-model="receiveInventory"
            :visible="isIndividualTenant || isCompanyTypeWarehouse"
            text="Receive Inventory"
            class="col"
          >
          </dx-util-check-box>
          <dx-util-button text="Submit" type="success" class="px-2 my-half" @click="submitData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shipService from '@/http/requests/ship/shipService'
import commonEntityService from '@/http/requests/common/commonEntityService'
import { Notify } from '@robustshell/utils/index'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import moment from 'moment'
import store from '@/store'
import companyService from '@/http/requests/company/companyService'
import useCurrentUser from '@/libs/app/current-user'

export default {
  components: {
    DxTextBoxButton,
  },
  props: {
    csvData: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { isCompanyTypeWarehouse, isIndividualTenant } = useCurrentUser()
    return {
      isCompanyTypeWarehouse,
      isIndividualTenant,
    }
  },
  data: () => ({
    selectedWarehouse: 0,
    selectedStore: 0,
    batchName: '',
    stores: [],
    warehouses: [],
    validData: false,
    accountNo: '',
    companyId: '',
    companyName: '',
    receiveInventory: false,
  }),
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    accountTextBoxButtonOptions() {
      return {
        text: 'Get Account',
        type: 'default',
        onClick: () => {
          const accountNo = this.accountNo.replace(/\D/g, '')
          this.getCompanyByAccountNo(accountNo)
        },
      }
    },
  },
  watch: {
    csvData: () => {},
  },
  mounted() {
    this.getStores()
    this.getWarehouse()
  },
  methods: {
    onFocusOut(e) {
      const accountNo = e.event.target.value
      this.accountNo = accountNo.replace(/\D/g, '')
      this.getCompanyByAccountNo(this.accountNo)
    },
    getCompanyByAccount(e) {
      if (e.event.keyCode !== 13) return
      const accountNo = e.event.target.value
      this.accountNo = accountNo.replace(/\D/g, '')
      this.getCompanyByAccountNo(this.accountNo)
    },
    getCompanyByAccountNo(accountNo) {
      if (!accountNo || accountNo === null || accountNo === 0 || accountNo === '' || accountNo === undefined) {
        Notify.error('Please enter a valid account no')
        return
      }
      companyService.fetchByAccountNo(accountNo).then(result => {
        this.companyId = result.data.id
        this.companyName = result.data.name
        this.accountNo = accountNo
        this.getStores()
      })
    },
    getStores() {
      if (this.isCompanyTypeWarehouse && this.accountNo) {
        commonEntityService
          .fetchStoresByParentCompanyAccountNo(this.accountNo)
          .then(result => {
            const { data } = result
            this.setStores(data)
          })
      } else {
        commonEntityService.fetchStoresForCurrentCompany().then(result => {
          const { data } = result
          this.setStores(data)
        })
      }
    },
    setStores(data) {
      this.stores.splice(0, this.stores.length)
      if (data.length > 1) {
        this.stores.push({ value: 0, text: 'Select a store' })
      }
      data.forEach(element => {
        this.stores.push({ value: element.id, text: element.name })
      })
      if (this.stores.length === 1) {
        this.selectedStore = this.stores[0].value
      }
    },
    getWarehouse() {
      const self = this
      self.warehouses.push({ value: 0, text: 'Warehouse (All)' })
      self.selectedWarehouse = 0
      shipService.fetchWarehouses().then(result => {
        result.data.forEach(element => {
          if (element.name.toLowerCase() !== 'common') {
            self.warehouses.push({ value: element.id, text: element.name })
          }
        })
      })
    },
    submitData() {
      if (!this.validateForm()) {
        return false
      }
      const params = {
        batchName: encodeURIComponent(this.batchName),
        store: this.selectedStore,
        warehouse: this.selectedWarehouse,
        receiveInventory: this.receiveInventory,
      }
      const data = {
        data: JSON.parse(JSON.stringify(this.csvData)),
        params: JSON.parse(JSON.stringify(params)),
      }
      shipService
        .addShipCsvUpload(data)
        .then(result => {
          const batch = result.data
          const ts = moment(batch.shipDate).valueOf()
          store.dispatch('appLocal/setUploadedShipmentItemsParams', {
            batchId: batch.id,
            batchName: batch.batchName,
            shipDate: ts,
          })
          this.$router.push({ name: 'route-shipment-items' })
        })
      return true
    },
    validateForm() {
      const self = this
      if (!self.batchName) {
        Notify.danger('Please enter a batch name')
        return false
      }
      if (!self.selectedStore) {
        Notify.danger('Please select a store')
        return false
      }
      if (!self.selectedWarehouse) {
        Notify.danger('Please select a destination warehouse')
        return false
      }
      return true
    },
  },
}
</script>
