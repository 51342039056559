<template>
  <div class="row">
    <div v-if="errorSummary.length > 0" class="col-md-12 mb-1">
      <dx-util-list
      :data-source="errorSummary"
      :grouped="true"
      :collapsible-groups="true"
      show-scrollbar="always"
      :hover-state-enabled="false"
      :focus-state-enabled="false"
      :element-attr="listAttributes"
      />
    </div>
    <div v-else class="col-md-12">
      <fileupolad-save v-if="csvData" :csv-data="grid.tableData" />
    </div>
    <div class="col-md-12">
        <dx-data-grid
          id="fileUploadItemsGrid"
          ref="mainGrid"
          key-expr="Order"
          :height="gridSetHeight"
          :data-source="grid.tableData"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          @row-prepared="setErrorRow"
        >
          <dx-column data-field="Order" caption="#" />
          <dx-column data-field="TrackingNumber" />
          <dx-column data-field="OrderNumber" />
          <dx-column data-field="OrderNumberSold" />
          <dx-column data-field="PalletId" />
          <dx-column data-field="PurchaseDate" />
          <dx-column data-field="ShipmentDate" />
          <dx-column data-field="ExpirationDate" />
          <dx-column data-field="ASIN" />
          <dx-column data-field="MSKU" />
          <dx-column data-field="UPC" />
          <dx-column data-field="FNSKU" />
          <dx-column data-field="SerialNo" />
          <dx-column data-field="ProductTitle" :width="400" />
          <dx-column data-field="ManufactureOrSupplier" />
          <dx-column data-field="ProductGroup" />
          <dx-column data-field="NumberOfUnits" />
          <dx-column data-field="Condition" />
          <dx-column data-field="Instructions" />
          <dx-column data-field="Cost" />
          <dx-column data-field="SalePrice" />
          <dx-column data-field="Fragile" />
          <dx-column data-field="MultiPack" />
          <dx-column data-field="Bundle" />
          <dx-column data-field="CountPerBundle" />
          <dx-column data-field="Action" />
          <dx-column data-field="locationName" />
          <dx-column data-field="Notes" />
          <dx-column data-field="Tags" />
          <dx-column data-field="PurchasedBy" />
          <dx-column data-field="Source" />
          <dx-paging :page-size="500" />
        </dx-data-grid>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-unused-vars
import GridBase from '@core/dev-extreme/mixins/grid/gridBase'
import toUpper from 'lodash/toUpper'
import isNaN from 'lodash/isNaN'
import FileUploadSave from '../file-upload-save/FileUploadSave.vue'
import {
  isNumber,
  isNumeric,
  isRequired,
  isExist,
  isRequiredAny,
  isValidFnskuSameAsin,
  isValidMsku,
  isValidAsin,
  isCountNumberOfUnits,
  isCountPerBundle,
  isCountWheNoBundleOrMultipack,
  isCurrency,
  isBundleOrMultipack,
} from '../../validationItem'

const actions = [
  'B2B',
  'DROPSHIP',
  'FBA',
  'WFS',
  'FBM',
  'FORWARDING',
  'REMOVAL_ORDER',
  'RETURN',
  'STORAGE',
]
const conditions = [
  'NEW',
  'USED_LIKE_NEW',
  'USED_VERY_GOOD',
  'USED_GOOD',
  'USED_ACCEPTABLE',
  'COLLECTIBLE_LIKE_NEW',
  'COLLECTIBLE_GOOD',
  'COLLECTIBLE_ACCEPTABLE',
  'REFURBISHED',
]

export default {
  components: {
    'fileupolad-save': FileUploadSave,
  },
  mixins: [GridBase],
  props: {
    csvData: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    grid: {
      tableData: [],
    },
    noValidData: false,
    listAttributes: {
      id: 'errorList',
    },
    errorSummary: [],
  }),
  mounted() {
    this.initData()
  },
  methods: {
    gridSetHeight() {
      return window.innerHeight / 1.4
    },
    initData() {
      this.csvData.forEach((item, index) => {
        const item1 = JSON.parse(JSON.stringify(item).replace(/"\s+|\s+"/g, '"'))
        const result = JSON.parse(JSON.stringify(this.checkDataItem(item1, index)))
        this.grid.tableData.push(
          {
            TrackingNumber: result.TrackingNumber,
            OrderNumber: result.OrderNumber,
            OrderNumberSold: result.OrderNumberSold,
            PalletId: result.PalletId,
            PurchaseDate: result.PurchaseDate,
            ShipmentDate: result.ShipmentDate,
            ASIN: result.ASIN,
            MSKU: result.MSKU,
            UPC: result.UPC,
            FNSKU: result.FNSKU,
            SerialNo: result.SerialNo,
            ProductTitle: result.ProductTitle,
            ManufactureOrSupplier: result.ManufactureOrSupplier,
            ProductGroup: result.ProductGroup,
            NumberOfUnits: result.NumberOfUnits,
            Condition: result.Condition,
            Instructions: result.Instructions,
            Cost: result.Cost,
            SalePrice: result.SalePrice,
            Fragile: result.Fragile,
            MultiPack: result.MultiPack,
            Bundle: result.Bundle,
            CountPerBundle: result.CountPerBundle,
            Action: result.Action,
            locationName: result.locationName || '',
            Notes: result.Notes,
            Order: result.Order,
            Tags: result.Tags,
            PurchasedBy: result.PurchasedBy,
            Source: result.Source,
          },
        )
      })
    },
    setErrorRow(e) {
      if (e.rowType === 'data' && e.data.rowVariant === true) {
        e.rowElement.style.backgroundColor = '#ea5455'
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '')
      }
    },
    isValidUpc(value) {
      if (value !== '' && value !== null && typeof value !== 'undefined') {
        const regex = /^[0-9]{12,13}$/gm
        return regex.test(value)
      }
      return true
    },
    checkDataItem(item, index) {
      const row = this.normalizeRowData(item)
      row.Order = index + 1
      const message = []

      if (!isValidAsin(row.ASIN) && !isValidMsku(row.MSKU)) message.push('Please enter a valid ASIN value.')
      if (!isValidMsku(row.MSKU) && !isValidAsin(row.ASIN)) message.push('Please enter a valid MSKU value.')
      if (!isValidFnskuSameAsin(row.FNSKU, row.ASIN)) message.push('Please enter a valid FNSKU value.')
      if (!this.isValidUpc(row.UPC)) message.push('Please enter a valid UPC value.')

      if (!isRequiredAny(row.TrackingNumber, row.OrderNumber)) {
        message.push('Please enter Tracking Number or Order Number value.')
      }
      if (!isRequiredAny(row.ASIN, row.MSKU)) {
        message.push('Please enter ASIN or MSKU value.')
      }
      if (!isRequired(row.ProductTitle)) {
        row.ProductTitle = ''
        message.push('Please enter a Product Title value.')
      }
      if (!isRequired(row.ManufactureOrSupplier)) {
        row.ManufactureOrSupplier = ''
        message.push('Please enter a Manufacturer Or Supplier value.')
      }
      if (!isNumber(row.NumberOfUnits)) {
        row.NumberOfUnits = ''
        message.push('Please enter Number of Units value.')
      }
      if (!isCountNumberOfUnits(row.Bundle, row.MultiPack, row.NumberOfUnits)) {
        row.NumberOfUnits = ''
        message.push('Number of Units must be greater than 0, used in this Bundle/Multipack.')
      }
      if (!isBundleOrMultipack(item.Bundle, item.MultiPack)) {
        message.push('Please make sure that either of multipack or bundle can be YES.')
      }
      // Purchase Date
      if (!this.isValidPurchaseDate(row)) {
        row.PurchaseDate = ''
        message.push('Please enter a valid date like YYYY-MM-DD (2021-03-26) in text format.')
      }
      // Expiration Date
      // Shipment Date
      if (!this.isValidShipmentDate(row)) {
        row.ShipmentDate = ''
        message.push('Please enter a valid date like YYYY-MM-DD (2021-03-26) in text format.')
      }
      // Expiration Date
      if (!this.isValidExpirationDate(row)) {
        row.ExpirationDate = ''
        message.push('Please enter a valid date like YYYY-MM-DD (2021-03-26) in text format.')
      }

      if (!isExist(row.Condition, conditions)) {
        message.push(
          'Please enter a Condition value. Valid conditions values NEW, USED_LIKE_NEW, USED_VERY_GOOD, USED_GOOD, USED_ACCEPTABLE, COLLECTIBLE_LIKE_NEW, COLLECTIBLE_GOOD, COLLECTIBLE_ACCEPTABLE, REFURBISHED',
        )
      }
      if (!isCurrency(row.Cost)) {
        if (!row.Cost) row.Cost = ''
        message.push('Please enter a Cost value.')
      }
      if (parseFloat(row.Cost) === 0) {
        message.push('Please enter valid cost.')
      }
      if (!isCurrency(row.SalePrice)) {
        if (!row.SalePrice) row.SalePrice = ''
        message.push('Please enter a Sale Price value.')
      }
      if (!isRequired(row.Action) || !isExist(row.Action, actions)) {
        row.Action = ''
        message.push('Please enter a valid Action value. Valid action values FBA, WFS, FBM, RETURN, REMOVAL_ORDER, FORWARDING, STORAGE')
      }

      if (!isCountPerBundle(row.Bundle, row.MultiPack, row.CountPerBundle)) {
        row.CountPerBundle = 0
        message.push('Please enter a Count Per Bundle value for the number of units to be used in this Bundle/Multipack. Please make sure that you enter number of sellable items in Number of Units column')
      }

      if (!isCountWheNoBundleOrMultipack(row.Bundle, row.MultiPack, row.CountPerBundle)) {
        message.push('Please enter 0 for Count Per Bundle value if you do not want to use Bundle or Multipack.')
      }

      if (message.length > 0) {
        row.rowVariant = true
        this.errorSummary.push({
          key: `Line ${index + 1}: There are ${message.length} errors in line ${index + 1}`,
          items: message,
        })
      }
      return row
    },
    normalizeRowData(item) {
      if (item.Action) item.Action = toUpper(item.Action)
      if (item.Condition) item.Condition = toUpper(item.Condition)
      if (item.locationName) item.locationName = toUpper(item.locationName) || ''
      if (item.Fragile) item.Fragile = toUpper(item.Fragile)
      if (item.Fragile !== 'YES' && item.Fragile !== 'NO') item.Fragile = 'NO'
      if (item.Bundle) item.Bundle = toUpper(item.Bundle)
      if (item.Bundle !== 'YES' && item.Bundle !== 'NO') item.Bundle = 'NO'
      if (item.MultiPack) item.MultiPack = toUpper(item.MultiPack)
      if (item.MultiPack !== 'YES' && item.MultiPack !== 'NO') item.MultiPack = 'NO'

      item.Cost = String(item.Cost).replace(',', '.')
      item.Cost = parseFloat(item.Cost.replace(/[^.0-9]/g, '')).toFixed(2)

      item.SalePrice = String(item.SalePrice).replace(',', '.')
      item.SalePrice = parseFloat(item.SalePrice.replace(/[^.0-9]/g, '')).toFixed(2)

      if (item.NumberOfUnits !== '' && item.NumberOfUnits !== null && typeof item.NumberOfUnits !== 'undefined' && typeof item.NumberOfUnits !== 'number') {
        const parsed = parseInt(item.NumberOfUnits.trim(), 10)
        item.NumberOfUnits = isNaN(parsed) ? 0 : parsed
      }
      if (!item.NumberOfUnits) item.NumberOfUnits = 0

      if (item.CountPerBundle !== '' && item.CountPerBundle !== null && typeof item.CountPerBundle !== 'undefined' && typeof item.CountPerBundle !== 'number') {
        const parsed = parseInt(item.CountPerBundle.trim(), 10)
        item.CountPerBundle = isNaN(parsed) ? 0 : parsed
      }
      if (!item.CountPerBundle) item.CountPerBundle = 0

      if (item.ManufactureOrSupplier) {
        if (isNaN(item.ManufactureOrSupplier)) {
          item.ManufactureOrSupplier = item.ManufactureOrSupplier.trim()
        }
      }

      return item
    },
    getFormattedMonth(month) {
      return month < 10 ? `0${month}` : month
    },

    excelDateToJSDate(date) {
      return new Date(Math.round((date - 25569) * 86400 * 1000))
    },
    excelDateRangeValid(date) {
      const excelStartDate = new Date(-2209075200000)
      const excelEndDate = new Date(4641004800000)
      return date >= excelStartDate && date <= excelEndDate
    },
    isValidShipmentDate(item) {
      let date = item.ShipmentDate
      if (date !== '' && date !== null && typeof date !== 'undefined') {
        const regExDateFormat = /^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/
        if (regExDateFormat.test(date)) {
          date = date.replace(/\//g, '-')
          item.ShipmentDate = date
        }

        if (isNumeric(date)) {
          date = this.excelDateToJSDate(date)
          if (!this.excelDateRangeValid(date)) {
            return false
          }
          item.ShipmentDate = date
          return true
        }
        const regEx = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/
        return date.match(regEx) != null
      }
      return false
    },
    isValidPurchaseDate(item) {
      if (item.PurchaseDate === '' || item.PurchaseDate === null || typeof item.PurchaseDate === 'undefined') {
        item.PurchaseDate = item.ShipmentDate
      }
      let date = item.PurchaseDate
      if (date !== '' && date !== null && typeof date !== 'undefined') {
        const regExDateFormat = /^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/
        if (regExDateFormat.test(date)) {
          date = date.replace(/\//g, '-')
          item.PurchaseDate = date
        }

        if (isNumeric(date)) {
          date = this.excelDateToJSDate(date)
          if (!this.excelDateRangeValid(date)) {
            return false
          }
          item.PurchaseDate = date
          return true
        }
        const regEx = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/
        return date.match(regEx) != null
      }
      return false
    },
    isValidExpirationDate(item) {
      let date = item.ExpirationDate

      if (date !== '' && date !== null && typeof date !== 'undefined') {
        const regExDateFormat = /^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/
        if (regExDateFormat.test(date)) {
          date = date.replace(/\//g, '-')
          item.ExpirationDate = date
        }

        if (isNumeric(date)) {
          date = this.excelDateToJSDate(date)
          if (!this.excelDateRangeValid(date)) {
            return false
          }
          item.ExpirationDate = date
          return true
        }
        const regEx = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/
        return date.match(regEx) != null
      }
      return true
    },
  },
}
</script>

<style lang="scss">
  #errorList {
    .dx-scrollable-content {
      padding-right: 0px !important;
    }
    .dx-list-group {
      background-color: rgba(255, 255, 255,1.0);
      .dx-list-group-header {
        color: #c0392b;
        padding: 10px 10px 10px !important;
        border-bottom: 1px solid rgba(164, 176, 190,1.0) !important;
        border-top: 1px solid rgba(164, 176, 190,1.0) !important;
        &::before{
          border-top-color: #c0392b;
        }
      }
      .dx-list-item{
        border-top:  1px solid rgba(164, 176, 190,1.0) !important;
        .dx-item-content {
          &.dx-list-item-content{
            color: #c0392b;
          }
        }
      }
    }
  }
</style>>
